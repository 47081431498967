'use client';

import '@/lib/initDatadogRum';
import {env} from '@/env';
import {jucyAttribution} from '@/lib/jucyAttribution';
import { LicenseInfo } from '@mui/x-license-pro';
globalThis.jucyConfig = {
    rentalsApiBaseUrl: `${env.NEXT_PUBLIC_RENTALS_API_BASE_URL}`.replace(/\/$/, ''),
    rentalsApiMiddleware: [
        {
            pre: async ({ url, init }) => ({
                url,
                init: {
                    ...init,
                    next: {
                        ...init?.next,
                        revalidate: init?.next?.revalidate || 60,
                    },
                },
            }),
        },
    ],
};

LicenseInfo.setLicenseKey('50a2ce9c677e8555b0805b112d07311dTz0xMDkxNjksRT0xNzczNDQ2Mzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');
jucyAttribution.init();

export const ClientInit = () => null;
